import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
//import { getCurrentLangKey/*, getLangs*//*, getUrlForLang*/ } from 'ptz-i18n';
import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider } from 'react-intl';
import { setLanguageKey, setLocalePath, setLanguageList } from '../components/lang-service';

import 'intl';
//import './index.css'

const Layout = ({ children, location, i18nMessages }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={(data) => {
        const url = location.pathname;
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const language = /^.{8}[^\/]*\/([^\/]*)/.exec(location.href)[1] || defaultLangKey;
        const lang = language.replace(new RegExp((langs || []).join('|'), 'gi'), (lang) => {
          return `\/${lang}\/`;
        });
		//getCurrentLangKey(langs, defaultLangKey, url); // 'it'
        const langKey = lang === `\/${language}\/` ? language : defaultLangKey;
        const route = (/^\/([^/]+)(.*)$/.exec(url) || []);
        (route.length > 2) && ([route[1], route[2]] = [route[2], route[1]]);
        // const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/')
        setLanguageKey(langKey);
        setLanguageList(langs);
        setLocalePath('/'.concat(langKey));
        return (
          <IntlProvider locale={langKey} messages={i18nMessages}>
            <>
              <Helmet
                title="AlifaCloud"
                meta={[{ name: 'company', content: 'Siap+Micros SpA' }, { name: 'keywords', content: 'datalogger, cloud' }]}
              >
              </Helmet>
               {React.cloneElement(children, { pathname: url, langs, route, langKey })}
            </> 
          </IntlProvider>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.func
}

export default Layout;

